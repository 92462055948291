package com.picme

import com.lightningkite.kiteui.navigation.*
import com.picme.LandingScreen
import com.picme.installrefer.InstallReferStartScreen
import com.picme.installrefer.InstallReferFinishScreen
import com.picme.views.ConfirmEmail
import com.picme.views.PartialAuthUploadView
import com.picme.views.ReferAFriend
import com.picme.views.LogInScreen
import com.picme.views.ResetPassword
import com.picme.views.ConfirmResetPassword
import com.picme.views.MobileUnverifiedUserLanding
import com.picme.views.CreateAccount
import com.picme.views.LegacyUpload
import com.picme.views.LegacyShare
import com.picme.views.QrCodeOld
import com.picme.views.LoginOrSignUp
import com.picme.views.WelcomeTOSLanding
import com.picme.views.FinishCreateAccount
import com.picme.views.CollectionLanding
import com.picme.views.Plan
import com.picme.views.PasswordAndSecurity
import com.picme.views.CollectionDetail
import com.picme.views.ProfileScreen
import com.picme.views.QrAccept


val AutoRoutes = Routes(
    parsers = listOf(
        label@{ 
            if (it.segments.size != 0) return@label null
            LandingScreen(
            ).apply {
            }
        },
        label@{ 
            if (it.segments.size != 1) return@label null
            if (it.segments[0] != "install-refer-start") return@label null
            InstallReferStartScreen
            .apply {
            }
        },
        label@{ 
            if (it.segments.size != 1) return@label null
            if (it.segments[0] != "install-refer-finish") return@label null
            InstallReferFinishScreen(
            ).apply {
                UrlProperties.decodeFromStringMap("lastViewedCollection", it.parameters, this.lastViewedCollection)
                UrlProperties.decodeFromStringMap("shouldOpenQrCodeId", it.parameters, this.shouldOpenQrCodeId)
                UrlProperties.decodeFromStringMap("refreshToken", it.parameters, this.refreshToken)
            }
        },
        label@{ 
            if (it.segments.size != 1) return@label null
            if (it.segments[0] != "confirm-email") return@label null
            ConfirmEmail(
            ).apply {
            }
        },
        label@{ 
            if (it.segments.size != 2) return@label null
            if (it.segments[0] != "up-uploading") return@label null
            PartialAuthUploadView(
                urlSafeId = UrlProperties.decodeFromString(it.segments[1]),
            ).apply {
            }
        },
        label@{ 
            if (it.segments.size != 1) return@label null
            if (it.segments[0] != "refer-a-friend") return@label null
            ReferAFriend
            .apply {
            }
        },
        label@{ 
            if (it.segments.size != 1) return@label null
            if (it.segments[0] != "log-in") return@label null
            LogInScreen
            .apply {
            }
        },
        label@{ 
            if (it.segments.size != 1) return@label null
            if (it.segments[0] != "reset-password") return@label null
            ResetPassword
            .apply {
            }
        },
        label@{ 
            if (it.segments.size != 1) return@label null
            if (it.segments[0] != "confirm-reset-password") return@label null
            ConfirmResetPassword
            .apply {
            }
        },
        label@{ 
            if (it.segments.size != 1) return@label null
            if (it.segments[0] != "collection-unverified") return@label null
            MobileUnverifiedUserLanding
            .apply {
            }
        },
        label@{ 
            if (it.segments.size != 1) return@label null
            if (it.segments[0] != "create-account") return@label null
            CreateAccount
            .apply {
            }
        },
        label@{ 
            if (it.segments.size != 2) return@label null
            if (it.segments[0] != "up") return@label null
            LegacyUpload(
                urlSafeCollId = UrlProperties.decodeFromString(it.segments[1]),
            ).apply {
            }
        },
        label@{ 
            if (it.segments.size != 3) return@label null
            if (it.segments[0] != "up") return@label null
            LegacyShare(
                urlSafeCollId = UrlProperties.decodeFromString(it.segments[1]),
                sharingAuthCode = UrlProperties.decodeFromString(it.segments[2]),
            ).apply {
            }
        },
        label@{ 
            if (it.segments.size != 2) return@label null
            if (it.segments[0] != "qr") return@label null
            QrCodeOld(
                qrCodeId = UrlProperties.decodeFromString(it.segments[1]),
            ).apply {
            }
        },
        label@{ 
            if (it.segments.size != 1) return@label null
            if (it.segments[0] != "login-or-signup") return@label null
            LoginOrSignUp(
            ).apply {
            }
        },
        label@{ 
            if (it.segments.size != 1) return@label null
            if (it.segments[0] != "welcome") return@label null
            WelcomeTOSLanding
            .apply {
            }
        },
        label@{ 
            if (it.segments.size != 1) return@label null
            if (it.segments[0] != "create-account-finish") return@label null
            FinishCreateAccount
            .apply {
            }
        },
        label@{ 
            if (it.segments.size != 1) return@label null
            if (it.segments[0] != "collection") return@label null
            CollectionLanding
            .apply {
            }
        },
        label@{ 
            if (it.segments.size != 1) return@label null
            if (it.segments[0] != "plan") return@label null
            Plan
            .apply {
            }
        },
        label@{ 
            if (it.segments.size != 1) return@label null
            if (it.segments[0] != "password-and-security") return@label null
            PasswordAndSecurity
            .apply {
            }
        },
        label@{ 
            if (it.segments.size != 2) return@label null
            if (it.segments[0] != "collection") return@label null
            CollectionDetail(
                urlSafeId = UrlProperties.decodeFromString(it.segments[1]),
            ).apply {
            }
        },
        label@{ 
            if (it.segments.size != 1) return@label null
            if (it.segments[0] != "account") return@label null
            ProfileScreen
            .apply {
            }
        },
        label@{ 
            if (it.segments.size != 2) return@label null
            if (it.segments[0] != "in") return@label null
            QrAccept(
                codeId = UrlProperties.decodeFromString(it.segments[1]),
            ).apply {
            }
        },
    ),
    renderers = mapOf(
        LandingScreen::class to label@{
            if (it !is LandingScreen) return@label null
            val p = HashMap<String, String>()
            RouteRendered(UrlLikePath(
                segments = listOf(),
                parameters = p
            ), listOf())
        },
        InstallReferStartScreen::class to label@{
            if (it !is InstallReferStartScreen) return@label null
            val p = HashMap<String, String>()
            RouteRendered(UrlLikePath(
                segments = listOf("install-refer-start"),
                parameters = p
            ), listOf())
        },
        InstallReferFinishScreen::class to label@{
            if (it !is InstallReferFinishScreen) return@label null
            val p = HashMap<String, String>()
            UrlProperties.encodeToStringMap(it.lastViewedCollection.value, "lastViewedCollection", p)
            UrlProperties.encodeToStringMap(it.shouldOpenQrCodeId.value, "shouldOpenQrCodeId", p)
            UrlProperties.encodeToStringMap(it.refreshToken.value, "refreshToken", p)
            RouteRendered(UrlLikePath(
                segments = listOf("install-refer-finish"),
                parameters = p
            ), listOf(it.lastViewedCollection, it.shouldOpenQrCodeId, it.refreshToken))
        },
        ConfirmEmail::class to label@{
            if (it !is ConfirmEmail) return@label null
            val p = HashMap<String, String>()
            RouteRendered(UrlLikePath(
                segments = listOf("confirm-email"),
                parameters = p
            ), listOf())
        },
        PartialAuthUploadView::class to label@{
            if (it !is PartialAuthUploadView) return@label null
            val p = HashMap<String, String>()
            RouteRendered(UrlLikePath(
                segments = listOf("up-uploading", UrlProperties.encodeToString(it.urlSafeId)),
                parameters = p
            ), listOf())
        },
        ReferAFriend::class to label@{
            if (it !is ReferAFriend) return@label null
            val p = HashMap<String, String>()
            RouteRendered(UrlLikePath(
                segments = listOf("refer-a-friend"),
                parameters = p
            ), listOf())
        },
        LogInScreen::class to label@{
            if (it !is LogInScreen) return@label null
            val p = HashMap<String, String>()
            RouteRendered(UrlLikePath(
                segments = listOf("log-in"),
                parameters = p
            ), listOf())
        },
        ResetPassword::class to label@{
            if (it !is ResetPassword) return@label null
            val p = HashMap<String, String>()
            RouteRendered(UrlLikePath(
                segments = listOf("reset-password"),
                parameters = p
            ), listOf())
        },
        ConfirmResetPassword::class to label@{
            if (it !is ConfirmResetPassword) return@label null
            val p = HashMap<String, String>()
            RouteRendered(UrlLikePath(
                segments = listOf("confirm-reset-password"),
                parameters = p
            ), listOf())
        },
        MobileUnverifiedUserLanding::class to label@{
            if (it !is MobileUnverifiedUserLanding) return@label null
            val p = HashMap<String, String>()
            RouteRendered(UrlLikePath(
                segments = listOf("collection-unverified"),
                parameters = p
            ), listOf())
        },
        CreateAccount::class to label@{
            if (it !is CreateAccount) return@label null
            val p = HashMap<String, String>()
            RouteRendered(UrlLikePath(
                segments = listOf("create-account"),
                parameters = p
            ), listOf())
        },
        LegacyUpload::class to label@{
            if (it !is LegacyUpload) return@label null
            val p = HashMap<String, String>()
            RouteRendered(UrlLikePath(
                segments = listOf("up", UrlProperties.encodeToString(it.urlSafeCollId)),
                parameters = p
            ), listOf())
        },
        LegacyShare::class to label@{
            if (it !is LegacyShare) return@label null
            val p = HashMap<String, String>()
            RouteRendered(UrlLikePath(
                segments = listOf("up", UrlProperties.encodeToString(it.urlSafeCollId), UrlProperties.encodeToString(it.sharingAuthCode)),
                parameters = p
            ), listOf())
        },
        QrCodeOld::class to label@{
            if (it !is QrCodeOld) return@label null
            val p = HashMap<String, String>()
            RouteRendered(UrlLikePath(
                segments = listOf("qr", UrlProperties.encodeToString(it.qrCodeId)),
                parameters = p
            ), listOf())
        },
        LoginOrSignUp::class to label@{
            if (it !is LoginOrSignUp) return@label null
            val p = HashMap<String, String>()
            RouteRendered(UrlLikePath(
                segments = listOf("login-or-signup"),
                parameters = p
            ), listOf())
        },
        WelcomeTOSLanding::class to label@{
            if (it !is WelcomeTOSLanding) return@label null
            val p = HashMap<String, String>()
            RouteRendered(UrlLikePath(
                segments = listOf("welcome"),
                parameters = p
            ), listOf())
        },
        FinishCreateAccount::class to label@{
            if (it !is FinishCreateAccount) return@label null
            val p = HashMap<String, String>()
            RouteRendered(UrlLikePath(
                segments = listOf("create-account-finish"),
                parameters = p
            ), listOf())
        },
        CollectionLanding::class to label@{
            if (it !is CollectionLanding) return@label null
            val p = HashMap<String, String>()
            RouteRendered(UrlLikePath(
                segments = listOf("collection"),
                parameters = p
            ), listOf())
        },
        Plan::class to label@{
            if (it !is Plan) return@label null
            val p = HashMap<String, String>()
            RouteRendered(UrlLikePath(
                segments = listOf("plan"),
                parameters = p
            ), listOf())
        },
        PasswordAndSecurity::class to label@{
            if (it !is PasswordAndSecurity) return@label null
            val p = HashMap<String, String>()
            RouteRendered(UrlLikePath(
                segments = listOf("password-and-security"),
                parameters = p
            ), listOf())
        },
        CollectionDetail::class to label@{
            if (it !is CollectionDetail) return@label null
            val p = HashMap<String, String>()
            RouteRendered(UrlLikePath(
                segments = listOf("collection", UrlProperties.encodeToString(it.urlSafeId)),
                parameters = p
            ), listOf())
        },
        ProfileScreen::class to label@{
            if (it !is ProfileScreen) return@label null
            val p = HashMap<String, String>()
            RouteRendered(UrlLikePath(
                segments = listOf("account"),
                parameters = p
            ), listOf())
        },
        QrAccept::class to label@{
            if (it !is QrAccept) return@label null
            val p = HashMap<String, String>()
            RouteRendered(UrlLikePath(
                segments = listOf("in", UrlProperties.encodeToString(it.codeId)),
                parameters = p
            ), listOf())
        },
    ),
)
