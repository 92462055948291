package com.picme.components

import com.lightningkite.kiteui.*
import com.lightningkite.kiteui.models.*
import com.lightningkite.kiteui.reactive.*
import com.lightningkite.kiteui.views.*
import com.lightningkite.kiteui.views.direct.*
import com.lightningkite.kiteui.views.l2.icon
import com.picme.*
import com.picme.sdk2.generated.collection2.PCollection
import com.picme.views.*
import kotlinx.coroutines.launch

fun ViewWriter.collectionActions(
    collection: Readable<PCollection>,
    preferredPopoverDirection: PopoverPreferredDirection,
    setView: suspend (v: CollSubPage) -> Unit
) {
    centered - iconButtonTheme - menuButton {
        centered - icon(PIcon.more, "More Horizontal")
        preferredDirection = preferredPopoverDirection
        requireClick = true
        opensMenu {
            popoverWrapper(width = 20.rem) {

                launch {
                    CollectionState.toggleView(CollSubPage.Main)
                }
                val isOwner = sharedSuspending {
                    ownsPCollection(collection())
                }
                val collectionId = collection.state.raw.collectionId
                col {
                    popoverItem {
                        ::exists { isOwner() }
                        icon.source = PIcon.settings
                        text.content = "Settings"
                        button.onClick {
                            setView(CollSubPage.Settings)
                            closePopovers()
                        }
                    }

                    // Hide filter option until they are working

//                    popoverItem {
//                        icon.source = if(CollectionState.filtersOn.state.raw) {
//                            PIcon.filterfunnelon
//                        } else {
//                            PIcon.filterfunnel
//                        }
//                        text.content = "Filters"
//                        button.onClick {
//                            closePopovers()
//                            setView(CollSubPage.Filters)
//                        }
//                    }
                    popoverItem {
                        ::exists {
                            collection().images().all().isNotEmpty()
                        }
                        icon.source = PIcon.download
                        text.content = "Download all items"
                        button.onClick {
                            val message = when (Platform.current) {
                                Platform.Web -> "All items will be downloaded to your device as a zip file."
                                Platform.iOS -> "All items will be saved to your Camera Roll."
                                else -> "All items will be saved to your device."
                            }
                            closePopovers()
                            showConfirmDialog(
                                title = "Confirm Download",
                                confirmLabel = "Download",
                                content = message,
                                onConfirm = {
                                    collectionDownload(collectionId)
                                }
                            )
                        }
                    }

                    toggleGridSizePopover()

                    popoverItem {
                        icon.source = PIcon.refresh
                        text.content = "Refresh"
                        button.onClick {
                            closePopovers()
                            forceRefresh()
                        }
                    }

                    separatorTheme - separator()

                    popoverItem {
                        ::exists { !isOwner() }
                        icon.source = PIcon.unlink
                        text.content = "Disconnect"
                        button.onClick {
                            closePopovers()
                            showConfirmDialog(
                                title = "Disconnect Collection",
                                content = "Are you sure you want to disconnect from this collection?",
                                warning = "This action cannot be undone.",
                                confirmLabel = "Disconnect",
                                onConfirm = {
                                    session.awaitNotNull().collection2.revokeRights(
                                        collectionId = collectionId,
                                        userId = session.awaitNotNull().authenticatedUser().userId
                                    )
                                    navigateToCollOrLanding(notThisCollection = collectionId)
                                }
                            )
                        }
                    }

                    popoverItem {
                        ::exists { isOwner() }
                        icon.source = PIcon.deletetrash
                        text.content = "Delete all items"
                        button.onClick {
                            closePopovers()
                            dialogGeneric { close ->
                                h4 {
                                    content = "Confirm Delete"
                                    align = Align.Center
                                }
                                text("All items will be deleted from the collection.")
                                actionOrCancelSection(
                                    onCancel = { close() },
                                    actionButton = {
                                        importantButton("Delete") {
                                            session.awaitNotNull().collection2.deleteAllUploads(collectionId)
                                            close()
                                            showToast("Successfully moved images to trash")
                                        }
                                    }
                                )
                            }
                        }
                    }
                    popoverItem {
                        ::exists { isOwner() }
                        icon.source = PIcon.viewtrash
                        text.content = "View Trash"
                        button.onClick {
                            closePopovers()
                            setView(CollSubPage.Trash)
                        }
                    }
                }
            }
        }
    }
}


fun ViewWriter.toggleGridSizePopover() = popoverItem {
    icon::source {
        when (sharedSuspending { getGridSize() }()) {
            GridSize.Small -> PIcon.expand
            GridSize.Large -> PIcon.contract
        }
    }
    text::content {
        when (sharedSuspending { getGridSize() }()) {
            GridSize.Small -> "Large grid size"
            GridSize.Large -> "Small grid size"
        }
    }
    button.onClick {
        closePopovers()
        toggleGridSize()
    }
}