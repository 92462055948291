package com.picme.views

import com.lightningkite.kiteui.*
import com.lightningkite.kiteui.models.*
import com.lightningkite.kiteui.navigation.Screen
import com.lightningkite.kiteui.navigation.dialogScreenNavigator
import com.lightningkite.kiteui.reactive.*
import com.lightningkite.kiteui.views.*
import com.lightningkite.kiteui.views.direct.*
import com.picme.*
import com.picme.actuals.dropFile
import com.picme.actuals.preventDrop
import com.picme.components.*
import com.picme.sdk2.SafeIds
import com.picme.sdk2.generated.CollectionId
import com.picme.sdk2.generated.collection2.PCollection
import com.picme.views.share.*
import kotlinx.coroutines.delay
import kotlinx.coroutines.launch


enum class CollSubPage { Main, Settings, Guests, Invites, Trash, Filters }

@Routable("/collection/{urlSafeId}")
class CollectionDetail(val urlSafeId: String) : Screen {
    private val collectionId = CollectionId(SafeIds.decode(urlSafeId))
    private val showAddImageOptions = Property<Boolean>(false)
    override val title = shared {
        session.awaitNotNull().collection2.getCollectionLive(collectionId)().collection.name
    }

    val selectedTypeOfCollectionButtons = Property<CollSubPage?>(null)

    override fun ViewWriter.render() {
        val collection = sharedSuspending {
            selectedTypeOfCollectionButtons set (CollectionState.currView())
            session.awaitNotNull().collection2.getCollectionLive(collectionId)().collection
        }

        suspend fun backToMain() {
            CollectionState.setView(CollSubPage.Main)
        }
        expanding - stack {
            spacing = 0.dp
            col {
                spacing = 0.dp
                expanding - swapView {
                    launch { backToMain() }
                    swapping(
                        current = { CollectionState.currView() },
                        views = {
                            with(
                                when (it) {
                                    CollSubPage.Main -> CollectionImageView(collectionId, collection)
                                    CollSubPage.Settings -> CollectionSettings(collectionId, ::backToMain)
                                    CollSubPage.Guests -> GuestView(collectionId, ::backToMain)
                                    CollSubPage.Invites -> ShareView(collectionId, ::backToMain)
                                    CollSubPage.Trash -> ViewTrash(collectionId, ::backToMain)
                                    CollSubPage.Filters -> Filters(::backToMain)
                                }
                            ) {
                                render()
                            }
                        }
                    )
                }
                ThemeDerivation { it.withBack }.onNext - stack {
                    spacing = 0.dp
                    expanding - col {
                        spacing = 0.dp
                        ::exists {
                            isSmallScreen() && selectedTypeOfCollectionButtons() == CollSubPage.Trash
                        }
                        bar - stack {
                            atTop - separatorTheme - unpadded - separator()
                            trashActions(true)
                        }
                    }
                    expanding - col {
                        spacing = 0.dp
                        ::exists {
                            isSmallScreen() && selectedTypeOfCollectionButtons() == CollSubPage.Main
                        }
                        collectionButtonsBottom(collection, showAddImageOptions)
                    }
                }
            }
        }
    }
}

class CollectionImageView(val collectionId: CollectionId, val collection: Readable<PCollection>) : Screen {
    override fun ViewWriter.render() {
        col {
            spacing = 0.dp
            preventDrop()
            launch {
                while (true) {
                    delay(8000)
                    checkRefreshCollImages()
                }
            }
            reactiveScope {
                currentCollection.value = collection()
            }

            val paged = sharedSuspending {
                CollectionState.filterGuests()
                CollectionState.filterDate()
                collection().images()
            }

            collectionBanner.onNext - row {
                ::exists { CollectionState.filtersOn() && !CollectionState.selectItems.isSelecting() }
                spacing = 6.dp
                space()
                centered - bold - text {
                    ::content {
                        val guestStr = CollectionState.filterGuests().ifEmpty { null }
                            ?.let { " for ${it.size} ${"guest".pluralize(it.size)}" } ?: ""

                        val dateStr = CollectionState.filterDate()?.let {
                            " from ${
                                when (it) {
                                    DateOptions.Today -> "today"
                                    DateOptions.Yesterday -> "yesterday"
                                    DateOptions.ThisWeek -> "this week"
                                    DateOptions.Last7Days -> "the last 7 Days"
                                    DateOptions.ThisMonth -> "this month"
                                    DateOptions.Last30Days -> "the last 30 days"
                                    DateOptions.Custom -> "a custom date range"
                                }
                            }"
                        } ?: ""
                        "Photos$guestStr$dateStr"
                    }
                }
                expanding - space()
                iconButtonTheme - button {
                    bold - text("Clear")
                    onClick { CollectionState.clearFilters() }
                }
                space()
            }
            selectItemsBar(CollectionState.selectItems)

            expanding - col {
                spacing = 0.dp
                centered - sizeConstraints(minWidth = 150.dp) - col {
                    ::exists {
                        (!paged().pullingMore() && paged().all().isEmpty())
                    }
                    spacing = 1.rem
                    space()
                    outlinedButton - button {
                        row {
                            spacing = 0.rem
                            space { }
                            centered - icon {
                                source = PIcon.upload
                            }
                            expanding - space { }
                            centered - h6("Upload")
                            expanding - space { }
                        }
                        onClick { uploadExternalFiles(collectionId) }
                    }
                }
                centered - col {
                    ::exists {
                        sharedSuspending {
                            paged().all()
                            false
                        }()
                    }
                    space()
                    activityIndicator {
                        exists = true

                    }
                }
                imageRecyclerView(
                    colImages = sharedSuspending {
                        paged().all().toRecyclableInfo()
                    },
                    setup = {
                        reactiveSuspending {
                            collection().images().requireIndexLoaded = lastVisibleIndex() + 50
                            if (dialogScreenNavigator.stack().find { it::class == ImageViewPager::class } == null) {
                                setStatusBarColor(theme.background.closestColor())
                            }
                        }
                    },
                    navToImage = { img ->
                        ImageViewPager(
                            images = sharedSuspending {
                                collection().images().all().toImageDisplayInfo()
                            },
                            incomingImage = img.invoke()
                        ).apply { this.imageId.value = img() }
                    },
                    selectedImages = CollectionState.selectItems
                )
            }

            onlyWhen { Platform.current == Platform.Web && !isSmallScreen() } - sizeConstraints(
                minHeight = 140.dp
            ) - padded - dropFile(collectionId) { validFiles, invalidFiles ->
                if (validFiles.isNotEmpty()) {
                    uploadDroppedFiles(collId = collectionId, chosen = validFiles)
                }
                if (invalidFiles.isNotEmpty()) {
                    showToast(
                        "Upload failed for ${invalidFiles.size} ${"file".pluralize(invalidFiles.size)}",
                        "Invalid file type(s)",
                        type = ToastType.Error
                    )
                }
            }

        }
    }
}


val collectionBanner
    get() = ThemeDerivation {
        it.copy(
            background = Color.picmeAuthGrey,
            cornerRadii = CornerRadii.Constant(0.dp)
        ).withBack
    }