package com.picme.components

import com.lightningkite.kiteui.models.*
import com.lightningkite.kiteui.navigation.Screen
import com.lightningkite.kiteui.navigation.dialogScreenNavigator
import com.lightningkite.kiteui.reactive.*
import com.lightningkite.kiteui.setStatusBarColor
import com.lightningkite.kiteui.views.*
import com.lightningkite.kiteui.views.direct.*
import com.picme.*
import com.picme.actuals.disableContextMenu
import kotlin.math.round


data class RecyclableInfo(
    val id: String,
    val thumbnail: ImageSource,
    val isAd: Boolean,
)

fun ViewWriter.imageRecyclerView(
    colImages: Readable<List<RecyclableInfo>>,
    selectedImages: SelectItems,
    setup: RecyclerView.() -> Unit,
    navToImage: (suspend (image: Readable<RecyclableInfo>) -> Screen)? = null
) {
    expanding - recyclerView {
        exists = false
        ::exists { colImages().isNotEmpty() }
        spacing = 2.dp
        reactiveSuspending {
            columns =
                round(AppState.windowInfo().width.px / (if (getGridSize() == GridSize.Large) 220 else 110).dp.px).toInt()
        }
        children(colImages) { img ->
            unpadded - card - button {
                spacing = 0.rem
                stack {
                    sizeConstraints(aspectRatio = 1 to 1) - tweakTheme { it.copy(cornerRadii = CornerRadii.Constant(0.dp)) } - image {
                        useLoadingSpinners = false
                        // TODO: Debug why this is required on iOS
                        // Otherwise images display at about a third of the expected size. Is it an image caching thing?
                        // Is the thumbnail really just at a resolution that naturally appears small on high-res iOS screens?
                        naturalSize = true
                        disableContextMenu()
                        ::source { img().thumbnail }; scaleType = ImageScaleType.Crop
                    }
                    gravity(Align.Start, Align.Start) - sizeConstraints(
                        width = 2.rem,
                        height = 2.rem
                    ) - unpadded - image {
                        ::exists { selectedImages.selected().contains(img()) }
                        source = Resources.checkCircleSelected
                    }
                }


                onClick {
                    if (selectedImages.isSelecting()) {
                        if (selectedImages.selected().contains(img())) {
                            selectedImages.removeItem(img())
                        } else {
                            selectedImages.addItem(img())
                        }
                    } else {
                        setStatusBarColor(Color.picmeAuthGrey)
                        navToImage?.let { dialogScreenNavigator.navigate(it(img)) }
                    }
                }
            }
        }
        setup()
    }
}
