package com.picme.installrefer

import com.lightningkite.kiteui.*
import com.lightningkite.kiteui.models.*
import com.lightningkite.kiteui.navigation.Screen
import com.lightningkite.kiteui.navigation.screenNavigator
import com.lightningkite.kiteui.reactive.invoke
import com.lightningkite.kiteui.views.ViewWriter
import com.lightningkite.kiteui.views.buttonTheme
import com.lightningkite.kiteui.views.centered
import com.lightningkite.kiteui.views.direct.*
import com.lightningkite.kiteui.views.important
import com.picme.*
import com.picme.views.*
import kotlinx.coroutines.launch
import kotlinx.datetime.Clock
import kotlin.time.Duration.Companion.minutes

@Routable("/install-refer-start")
object InstallReferStartScreen : Screen, UnauthScreen {
    override fun ViewWriter.render() {
        launch {
            if (!Platform.probablyAppleUser || !Platform.usesTouchscreen) {
                screenNavigator.replace(CollectionLanding)
            }
        }
        themedBg - stack {
            stack {
                col {
                    centered - sizeConstraints(width = 20.rem, height = 20.rem) - image {
                        source = Resources.picmePaige
                    }
                    space()

                    col {
                        spacing = 0.dp
                        centered - FadedForeground.onNext - h3("Ready to")
                        centered - FadedForeground.onNext - h3("capture experiences?")
                    }

                    centered - sizeConstraints(width = 28.rem, height = 42.dp) - buttonTheme - important - button {
                        centered - row {
                            centered - h6("Get Started")
                            onClick {
                                // We switch to a different domain in order to get Safari to follow this link as a universal link and open in the app
                                val base = if (frontendUrl() == "https://app.picme.com") "https://staging.app.picme.com" else "https://app.picme.com"
                                val installReferFinishPath = screenNavigator.routes.render(InstallReferFinishScreen().apply {
                                    refreshToken.value = sessionRefreshToken()
                                    lastViewedCollection.value = currentCollection()
                                    lastScannedQr()
                                        ?.takeIf { it.timestamp.plus(15.minutes) > Clock.System.now() }
                                        ?.let {
                                            shouldOpenQrCodeId.value = it.codeId
                                        }
                                })?.urlLikePath?.render()
                                ExternalServices.openTab("$base/$installReferFinishPath")
                            }
                        }
                    }
                }
            }
        }
    }
}