package com.picme.components

import com.lightningkite.kiteui.ExternalServices
import com.lightningkite.kiteui.FileReference
import com.lightningkite.kiteui.models.*
import com.lightningkite.kiteui.reactive.*
import com.lightningkite.kiteui.views.*
import com.lightningkite.kiteui.views.direct.*
import com.picme.ifElse
import com.picme.picmeBlue

data class ImageUploadInfo(
    val newImage: FileReference? = null,
    val deleteOriginal: Boolean = false,
    val original: ImageRemote? = null
)

fun ViewWriter.chooseCoverIcon(chosenImage: Property<ImageUploadInfo>) = col {
    val isImage = shared { chosenImage().newImage != null || chosenImage().original != null }
    spacing = 0.2.rem
    h4("Collection Image")
    row {
        sizeConstraints(width = 5.rem, height = 5.rem) - centered - stack {
            spacing = 0.dp
            themeChoice += ThemeDerivation {
                it.copy(cornerRadii = CornerRadii.ForceConstant(1.rem), background = Color.gray.applyAlpha(0.4f)).withBack
            }
            image {
                ::exists { isImage() }
                refreshOnParamChange = true
                scaleType = ImageScaleType.Crop
                ::source { chosenImage().newImage?.let(::ImageLocal) ?: chosenImage().original }
            }
        }

        centered - col {
            themeChoice += ThemeDerivation {
                it.copy(
                    foreground = Color.picmeBlue,
                    cornerRadii = CornerRadii.ForceConstant(2.rem),
                    spacing = 0.2.rem
                ).withoutBack
            }
            imageActionButton("Choose Image") {
                ExternalServices.requestFile(listOf("image/*")).let {
                    if (it == null) return@let
                    chosenImage set ImageUploadInfo(newImage = it)
                }
            }
            imageActionButton("Reset", exists = isImage) {
                chosenImage set ImageUploadInfo(deleteOriginal = true)
            }
        }
    }
}


fun ViewWriter.imageActionButton(
    text: String,
    exists: Readable<Boolean> = Constant(true),
    onClick: suspend () -> Unit
) = atStart - padded - onlyWhen { exists() } - button {
    themeChoice += ThemeDerivation { it.withoutBack }
    onClick{onClick()}
    row {
        bold - text { content = text }
    }
}