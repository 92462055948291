package com.picme.views

import com.lightningkite.kiteui.*
import com.lightningkite.kiteui.models.Color
import com.lightningkite.kiteui.models.ThemeDerivation
import com.lightningkite.kiteui.models.dp
import com.lightningkite.kiteui.models.rem
import com.lightningkite.kiteui.navigation.Screen
import com.lightningkite.kiteui.navigation.screenNavigator
import com.lightningkite.kiteui.reactive.*
import com.lightningkite.kiteui.views.*
import com.lightningkite.kiteui.views.direct.*
import com.picme.*
import com.picme.components.eula
import com.picme.installrefer.InstallReferStartScreen

@Routable("welcome")
object WelcomeTOSLanding : Screen, UnauthScreen {

    override val title = Constant("")

    val acknowledged = PersistentProperty("tos-launch-flag", false)

    override fun ViewWriter.render() {
        themedBg - stack {
            stack {
                col {
                    space(multiplier = 6.0)
                    centered - sizeConstraints(width = 5.rem, height = 5.rem) - image {
                        source = Resources.picmelogo
                    }
                    centered - row {
                        themeChoice += ThemeDerivation { it.copy(foreground = Color.picmePurple).withoutBack }

                        centered - bold - text { content = "capture experiences" }
                    }
                    space(multiplier = 6.0)

                    centered - FadedForeground.onNext - h3("Welcome to PicMe")

                    space(multiplier = 0.5)

                    centered - sizeConstraints(width = 28.rem, height = 42.dp) - buttonTheme - important - button {
                        centered - row {
                            centered - h6("Continue")
                            onClick {
                                acknowledged.value = true
                                screenNavigator.replace(CollectionLanding)
//                                if (Platform.current == Platform.iOS) {
//                                    val installReferStartPath = screenNavigator.routes.render(InstallReferStartScreen)?.urlLikePath?.render()
//                                    ExternalServices.openTab("${frontendUrl()}/$installReferStartPath")
//                                }
                            }
                        }
                    }
                    space(multiplier = 0.01)
                    centered - FadedForeground.onNext - eula("By clicking Continue, you agree to our")
                    space(); space()
                    getTheAppCard("collection", "Get our free app.")
                }
            }
        }
    }
}