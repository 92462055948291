package com.picme.views

import com.lightningkite.kiteui.Routable
import com.lightningkite.kiteui.models.Align
import com.lightningkite.kiteui.reactive.*
import com.lightningkite.kiteui.views.*
import com.lightningkite.kiteui.views.direct.*
import com.picme.*
import com.picme.components.PicmeDialog
import com.picme.components.dialogTopBar
import com.picme.session
import com.picme.sdk2.caching.InviteCache
import com.picme.views.share.ViewInviteInfo
import com.picme.views.share.qrDisplay
import kotlinx.coroutines.launch

@Routable("refer-a-friend")
object ReferAFriend : PicmeDialog() {
    override val title = Constant("Refer A Friend")

    override fun ViewWriter.topBar(close: suspend () -> Unit) = dialogTopBar(
        title = title,
        onBack = close,
    )

    override fun ViewWriter.content() {
        expanding - padded - col {
            val qrCodeInfo = Property<ViewInviteInfo?>(null)
            val activatedCount = Property<Int?>(null)

            launch {
                val referral =
                    InviteCache.getReferralCode() ?: session.awaitNotNull().collection2.listInviteCodes(null, null)
                        .let { listResponse ->
                            listResponse.inviteCodes.firstOrNull {
                                it.clientInfo()?.type == InviteType.Referral
                            } ?: session.awaitNotNull().authentication.createReferralInviteCode(
                                name = "Referral",
                                clientInformation = serializeQrInfo(
                                    ClientInfo(type = InviteType.Referral)
                                ),
                            ).inviteCode
                        }

                qrCodeInfo set ViewInviteInfo(
                    inviteCode = referral,
                    permission = InviteType.Referral,
                    link = "${frontendUrl()}/${referral.sharePath()}",
                )
                InviteCache.setReferralCode(referral)

                activatedCount set session.awaitNotNull().authentication.listMyReferrals().referrals.size
            }
            space(); space()
            centered - activityIndicator {
                ::exists { qrCodeInfo() == null }
            }
            stack {
                ::exists { qrCodeInfo() != null }

                        qrDisplay(shared { qrCodeInfo() }) {
                            text {
                                ::exists { activatedCount() != null }
                                ::content { "Referrals: ${activatedCount()}" }
                                align = Align.Center
                            }

                    }

            }
        }
    }
}