package com.picme.components

import com.lightningkite.kiteui.models.Align
import com.lightningkite.kiteui.navigation.mainScreenNavigator
import com.lightningkite.kiteui.reactive.Property
import com.lightningkite.kiteui.reactive.awaitNotNull
import com.lightningkite.kiteui.reactive.bind
import com.lightningkite.kiteui.reactive.invoke
import com.lightningkite.kiteui.reactive.shared
import com.lightningkite.kiteui.views.ViewWriter
import com.lightningkite.kiteui.views.direct.TextField
import com.lightningkite.kiteui.views.direct.h4
import com.picme.createAccountIfNewUser
import com.picme.createInitialQrCodes
import com.picme.formCol
import com.picme.sdk2.generated.collection2.CreateCollectionBody
import com.picme.sdk2.toSafeEncoded
import com.picme.session
import com.picme.views.CollectionDetail


fun ViewWriter.createCollectionModal() {
    dialogGeneric { close ->
        val collName = Property("")

        h4 {
            content = "Create Collection"
            align = Align.Center
        }

        formCol {
            val tf: TextField
            styledTextField {
                label = "Collection name"
                content bind collName
                hint = "Name"
                tf = this.field
                field.requestFocus()
            }

            actionOrCancelSection(
                onCancel = { close() },
                actionButton = {
                    importantButton("Continue", field = tf,
                        enabled = shared { collName.await().isNotEmpty() }) {
                        // Silently create an unverified account when creating a collection without authentication
                        createAccountIfNewUser()

                        val created = session.awaitNotNull().collection2.createCollection(
                            CreateCollectionBody(name = collName())
                        )

                        createInitialQrCodes(created.collection)
                        session.awaitNotNull().collection2.forceRefresh()
                        collName set ""
                        close()
                        mainScreenNavigator.reset(CollectionDetail(created.collection.collectionId.raw.toSafeEncoded()))
                    }
                }
            )
        }
    }
}